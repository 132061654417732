<template>
  <div class="OurServices">

    <div class="section1">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex flex-column align-items-lg-center">
            <h3>Our Services</h3>
            <img class="mw-100 d-lg-block d-none" src="../../assets/images/for_you_school/GTR-school-16.png" alt="">
            <img class="mw-100 d-lg-none d-block" src="../../assets/images/for_you_school/GTR-school-m6.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="section2">
      <img class="mw-100 d-lg-block d-none" src="../../assets/images/for_you_school/GTR-school-1.png" alt="">
      <img class="mw-100 d-lg-none d-block" src="../../assets/images/for_you_school/GTR-school-m1.png" alt="">
    </div>

    <div class="section3">
      <div class="container">
        <div class="row" style="position: relative">
          <div class="col-12 item-h3 d-flex flex-column align-items-lg-center">
            <h3 class="mb-0">Discover What Our Recruitment Services</h3>
            <h3>Can Do For Your School</h3>
          </div>
          <div class="col-12 dis_box d-flex flex-column align-items-center">
            <img class="mw-100 d-lg-block d-none" src="../../assets/images/for_you_school/GTR-school-7.png" alt="" style="opacity: 0">
            <img class="mw-100 d-lg-none d-block" src="../../assets/images/for_you_school/GTR-school-m2.png" alt="" style="opacity: 0">
            <div class="item_box">
               <div class="col-lg-4 col-12">
                 <img class="mw-100" src="../../assets/images/for_you_school/GTR-school-6.png" alt="">
                 <p>Global Reach to various native English-speaking countries.</p>
               </div>
               <div class="col-lg-4 col-12">
                 <img class="mw-100" src="../../assets/images/for_you_school/GTR-school-5.png" alt="">
                 <p>A thorough and comprehensive recruitment process to ensure top-quality candidates are supplied to your school.</p>
               </div>
               <div class="col-lg-4 col-12">
                 <img class="mw-100" src="../../assets/images/for_you_school/GTR-school-4.png" alt="">
                 <p>Marketing across various social media platforms and job boards.</p>
               </div>
               <div class="col-lg-4 col-12">
                 <img class="mw-100" src="../../assets/images/for_you_school/GTR-school-3.png" alt="">
                 <p>Assistance in facilitating the visa application process for new teachers.</p>
               </div>
               <div class="col-lg-4 col-12">
                 <img class="mw-100" src="../../assets/images/for_you_school/GTR-school-2.png" alt="">
                 <p>Saving your school time and money in sourcing a teacher through our specialized recruitment services.</p>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section4">
      <div class="container">
        <div class="row">
          <div class="col-12 item-h3 d-flex flex-column align-items-lg-center">
            <h3 style="color: #4d4d4d">Overview Of Our Recruitment Process</h3>
          </div>
          <div class="col-12 dis_box">
            <img class="mw-100 d-lg-block d-none" src="../../assets/images/for_you_school/GTR-school-8.png" alt="">
            <img class="mw-100 d-lg-none d-block" src="../../assets/images/for_you_school/GTR-school-m3.png" alt="">
            <div class="item_box">
              <div class="col-lg-3 col-12">
                <div class="item-p d-flex flex-column d-lg-block d-none mb-3" style="font-weight: bolder;">
                  <div class="text-center">The school submits a</div>
                  <div class="text-center">request for a new teacher</div>
                </div>
                <p class="d-lg-none d-block" style="font-weight: bolder;" >The school submits a request for a new teacher</p>
                <p>This includes defining the requirements of the candidate, the job spec, and the target start date that you are requesting for.</p>
              </div>
              <div class="col-lg-3 col-12">
                <div class="item-p d-flex flex-column d-lg-block d-none mb-3" style="font-weight: bolder;">
                  <div class="text-center">Scan our existing</div>
                  <div class="text-center">database</div>
                </div>
                <p class="d-lg-none d-block" style="font-weight: bolder;" >Scan our existing database</p>
                <p>We have teachers on our database that we can filter and contact in accordance with their preferred destinations and salary expectations.</p>
              </div>
              <div class="col-lg-3 col-12">
                <div class="item-p d-flex flex-column d-lg-block d-none mb-3" style="font-weight: bolder;">
                  <div class="text-center">Shortlist and proceed with</div>
                  <div class="text-center"> hiring process</div>
                </div>
                <p class="d-lg-none d-block" style="font-weight: bolder">Shortlist and proceed with hiring process</p>
                <p>Provide a shortlist of suitable candidates for the school. Once a shortlisted candidate is chosen, we will facilitate the entire recruitment process, from the final interview to the gathering of documentation.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section5">
      <h3>Our Guarantee To You</h3>
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-12">
            <img class="mw-100 GTR-school-13" src="../../assets/images/for_you_school/GTR-school-12.png" alt="">
            <p>A quality shortlist of top candidates for your school delivered timeously.</p>
          </div>
          <div class="col-lg-3 col-12">
            <img class="mw-100 GTR-school-13" src="../../assets/images/for_you_school/GTR-school-11.png" alt="">
            <p>Having an efficient screening and interview system in place for selected candidates.</p>
          </div>
          <div class="col-lg-3 col-12">
            <img class="mw-100 GTR-school-13" src="../../assets/images/for_you_school/GTR-school-10.png" alt="">
            <p>Having a teacher ready to proceed with the visa application process.</p>
          </div>
          <div class="col-lg-3 col-12">
            <img class="mw-100 GTR-school-13" src="../../assets/images/for_you_school/GTR-school-9.png" alt="">
             <div>
               <p>The guarantee of replacing a teacher free of charge should they resign within one month of employment*.</p>
               <p style="margin-top: 0">*T&C’s apply.</p>
             </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section6">
      <div class="container">
        <div class="row">
          <div class="col-12 item-h3 d-flex flex-column align-items-lg-center">
            <h3>Our Global Reach</h3>
            <p class="mt-lg-3 mt-0">Canada / USA / UK / Ireland / South Africa / Australia /</p>
            <p>Hong Kong / Macau / New Zealand</p>
            <img class="mw-100 GTR-school-13" src="../../assets/images/for_you_school/GTR-school-13.png" alt="">
<!--            <img class="mw-100 GTR-school-14 d-lg-block d-none" src="../../assets/images/for_you_school/GTR-school-14.png" alt="">-->
<!--            <img class="mw-100 d-lg-none d-block" src="../../assets/images/for_you_school/GTR-school-m4.png" alt="">-->
          </div>
        </div>
      </div>
    </div>

    <div class="section7">
      <div class="ContactUs">
        <div class="section1">
          <div class="leftBox">
            <h1 class="d-lg-none d-block">Contact Us</h1>
            <h1 class="d-lg-block d-none">Contact</h1>
            <h1 class="d-lg-block d-none">Us</h1>
            <p>We are here for</p>
            <p>questions or consulting</p>
          </div>
          <div class="rightBox">
            <div class="itemBox">
              <div class="itemBot">
                <p>Simply complete the contact form and we will get back to you!</p>
                <div class="inputBox">
                  <span>First name:</span>
                  <el-input class="inputItem" id="last_name #active" v-model="RewardsData.last_name" @input="getLastName" placeholder="Please input first name."></el-input>
                  <i class="el-icon-warning" v-show="isLastName"></i>
                </div>
                <div class="inputBox">
                  <span>Surname:</span>
                  <el-input class="inputItem" id="surname" v-model="RewardsData.surname" @input="getSurname" placeholder="Please input surname."></el-input>
                  <i class="el-icon-warning" v-show="isSurname"></i>
                </div>
                <div class="inputBox">
                  <span>School / Company(if applicable):</span>
                  <el-input class="inputItem" id="schoolCompany" v-model="RewardsData.schoolCompany" @input="getSchoolCompany" placeholder="Please input School / Company."></el-input>
                  <i class="el-icon-warning" v-show="isSchoolCompany"></i>
                </div>
                <div class="inputBox">
                  <span>Phone:</span>
                  <el-input class="inputItem" id="phone" v-model="RewardsData.phone" @input="getPhone" placeholder="Please input contact No."></el-input>
                  <i class="el-icon-warning" v-show="isPhone"></i>
                </div>
                <div class="inputBox">
                  <span>Email:</span>
                  <el-input class="inputItem" id="email" v-model="RewardsData.email" @input="getEmail" placeholder="Please input e-mail address."></el-input>
                  <i class="el-icon-warning" v-show="isEmail"></i>
                </div>
                <div class="inputBox textarea">
                  <span>Message:</span>
                  <el-input
                      type="textarea"
                      @input="getTextarea"
                      :autosize="{ minRows: 8, maxRows: 8}"
                      placeholder="Please input message."
                      v-model="RewardsData.textarea">
                  </el-input>
                  <i style="margin-top: 1%" class="el-icon-warning" v-show="isTextarea"></i>
                </div>
                <div class="buttonBox">
                  <el-button class="btn" @click="getSubmit">Submit</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item_p">
          <p>
            We are the sole owners of the information collected on this site. We only collect and have access to information
            that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.
          </p>
          <p>
            We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with
            any third party outside of our organisation, other than as necessary to fulfil your request.
          </p>
        </div>
      </div>
    </div>

    <Footer_Who/>

    <Footer/>
  </div>
</template>

<script>
import Footer from "@/views/Layout/Footer.vue";
import Footer_Who from "@/views/Layout/Footer_Who.vue";
import axios from 'axios';

export default {
  components: {Footer_Who, Footer},
  data() {
    return {
      isLastName:false,
      isSurname:false,
      isSchoolCompany:false,
      isPhone:false,
      isEmail:false,
      isTextarea:false,
      RewardsData:{
        last_name: '',
        surname: '',
        schoolCompany:'',
        phone: '',
        email: '',
        textarea:'',
        type: false
      }
    }
  },
  methods: {
    getLastName(event) {
      if(this.RewardsData.last_name === '') {
        this.isLastName = true;
        this.RewardsData.type = false;
      }else {
        this.isLastName = false;
        this.RewardsData.last_name = event;
        this.RewardsData.type = true;
      }
    },
    getSurname(event) {
      if(this.RewardsData.surname === '') {
        this.isSurname = true;
        this.RewardsData.type = false;
      }else {
        this.isSurname = false;
        this.RewardsData.surname = event;
        this.RewardsData.type = true;
      }
    },
    getSchoolCompany(event) {
      if(this.RewardsData.schoolCompany === '') {
        this.isSchoolCompany = true;
        this.RewardsData.type = false;
      }else {
        this.isSchoolCompany = false;
        this.RewardsData.schoolCompany = event;
        this.RewardsData.type = true;
      }
    },
    getPhone(event) {
      let regContact = new RegExp("^[0-9]*$");
      if (event === "" || (event !== "" && !regContact.test(event))) {
        this.isPhone = true;
        this.RewardsData.type = false;
      }
      else {
        this.isPhone = false;
        this.RewardsData.phone = event;
        this.RewardsData.type = true;

      }
    },
    getEmail(event) {
      let regEmail = /.+@.+\.[a-zA-Z]{2,4}$/;
      if(event === "" || (event !== "" && !regEmail.test(event))){
        this.isEmail = true;
        this.RewardsData.type = false;
      }
      else{
        this.isEmail = false;
        this.RewardsData.email = event;
        this.RewardsData.type = true;
      }
    },
    getTextarea(event) {
      if(this.RewardsData.textarea === '') {
        this.isTextarea = true;
        this.RewardsData.type = false;
      }else {
        this.isTextarea = false;
        this.RewardsData.textarea = event;
        this.RewardsData.type = true;
      }
    },
    getSubmit() {
      let regContact = new RegExp("^[0-9]*$");
      let regEmail = /.+@.+\.[a-zA-Z]{2,4}$/;
      if(this.RewardsData.last_name === ''){this.isLastName = true; this.RewardsData.type = false;}
      if(this.RewardsData.surname === ''){this.isSurname = true; this.RewardsData.type = false;}
      if(this.RewardsData.schoolCompany === ''){this.isSchoolCompany = true; this.RewardsData.type = false;}
      if(this.RewardsData.phone === '' || !regContact.test(this.RewardsData.phone)){this.isPhone = true; this.RewardsData.type = false;}
      if(this.RewardsData.email === '' || !regEmail.test(this.RewardsData.email)){this.isEmail = true; this.RewardsData.type = false;}
      if(this.RewardsData.textarea === ''){this.isTextarea = true; this.RewardsData.type = false;}

      if(this.RewardsData.type){
          axios.post('https://schoolnet.monkeytree.com.hk/monkeyTree/recruitment_send_email.jsp', {
          last_name: this.RewardsData.last_name,
          surname: this.RewardsData.surname,
          schoolCompany: this.RewardsData.schoolCompany,
          phone: this.RewardsData.phone,
          email: this.RewardsData.email,
          textarea: this.RewardsData.textarea
        }, {
       headers: {
           'Content-Type': 'application/x-www-form-urlencoded' 
         }
       })
        .then(res => {
          console.log(res);
		          console.log("成功提交！",this.RewardsData)
				  console.log("成功提交！",res.data)
          if (res.data.status === 200) {
            // 处理成功返回的数据
            console.log("邮件发送成功", res.data.result);
            this.$confirm('Submission Successful !', '', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              // type: 'warning',// 图标
              center: true
            })
          } else {
            // 处理失败的情况
            console.error("邮件发送失败", res.data.message);
          }
        })
        .catch(error => {
          // 其他错误
                console.log('Error', error.message);
        });
        // this.$api.getContact({
        //   last_name:this.RewardsData.last_name,
        //   surname:this.RewardsData.surname,
        //   schoolCompany:this.RewardsData.schoolCompany,
        //   phone:this.RewardsData.phone,
        //   email:this.RewardsData.email,
        //   textarea:this.RewardsData.textarea,
        // })
        // .then(res=>{
        //   console.log(res)
        //   if(res.data.status===200){
        //     return resolve(res.data.result);
        //   }else{
        //     return resolve([])
        //   }
        // })
        // this.$confirm('Submission Successful !', '', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   // type: 'warning',// 图标
        //   center: true
        // })
      }
    },
  }
}
</script>

<style>
.el-popup-parent--hidden {
  overflow: hidden;
  padding-right: 0px !important;
}
.el-message-box--center .el-message-box__btns {
  display: none;
}
.el-message-box--center  {
  padding-bottom: 50px !important;
  background:#DA191E !important;
  color: #fff;
  border: 0 !important;
}
.el-message-box__message p {
  line-height: 24px;
  color: #fff;
  font-size: 26px;
  margin: 10% 0% !important;
}
.el-message-box__headerbtn .el-message-box__close {
  color: #fff !important;
  font-size: 28px;
}
.el-message-box__headerbtn .el-message-box__close:hover {
  color: #fff;
  opacity: 0.8;
}
</style>