<template>
  <div class="ContactUs">
    <div class="section1">
      <div class="d-lg-block d-none leftBox">
        <img class="mw-100" src="../../assets/images/contact-us/dreamstime_l_52589931.jpg" alt="">
      </div>
      <div class="rightBox">
        <div class="itemBox">
          <div class="itemTop">
             <h1>Contact Us</h1>
             <p>We are here for questions or consulting</p>
           </div>
          <div class="itemBot">
            <p>Simply complete the contact form and we will get back to you!</p>
            <div class="inputBox">
              <span>First name:</span>
              <el-input class="inputItem" id="last_name #active" v-model="RewardsData.last_name" @input="getLastName" placeholder="Please input first name."></el-input>
              <i class="el-icon-warning" v-show="isLastName"></i>
            </div>
            <div class="inputBox">
              <span>Surname:</span>
              <el-input class="inputItem" id="surname" v-model="RewardsData.surname" @input="getSurname" placeholder="Please input surname."></el-input>
              <i class="el-icon-warning" v-show="isSurname"></i>
            </div>
            <div class="inputBox">
              <span>School / Company(if applicable):</span>
              <el-input class="inputItem" id="schoolCompany" v-model="RewardsData.schoolCompany" @input="getSchoolCompany" placeholder="Please input School / Company."></el-input>
              <i class="el-icon-warning" v-show="isSchoolCompany"></i>
            </div>
            <div class="inputBox">
              <span>Phone:</span>
              <el-input class="inputItem" id="phone" v-model="RewardsData.phone" @input="getPhone" placeholder="Please input contact No."></el-input>
              <i class="el-icon-warning" v-show="isPhone"></i>
            </div>
            <div class="inputBox">
              <span>Email:</span>
              <el-input class="inputItem" id="email" v-model="RewardsData.email" @input="getEmail" placeholder="Please input e-mail address."></el-input>
              <i class="el-icon-warning" v-show="isEmail"></i>
            </div>
            <div class="inputBox textarea">
              <span>Message:</span>
              <el-input
                  type="textarea"
                  @input="getTextarea"
                  :autosize="{ minRows: 8, maxRows: 8}"
                  placeholder="Please input message."
                  v-model="RewardsData.textarea">
              </el-input>
              <i style="margin-top: 1%" class="el-icon-warning" v-show="isTextarea"></i>
            </div>
            <div class="buttonBox">
              <el-button class="btn" @click="getSubmit">Submit</el-button>
            </div>
            <div class="pBox">
              <p>
                We are the sole owners of the information collected on this site. We only collect and have access to information that
                you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.
              </p>
              <p>
                We will use your information to respond to you, regarding the reason you contacted us. We will not share your
                information with any third party outside of our organisation, other than as necessary to fulfil your request.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-lg-none d-block smallBox">
        <img class="mw-100" src="../../assets/images/contact-us/dreamstime_l_52589930.jpg" alt="">
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/views/Layout/Footer.vue";
import axios from 'axios';


export default {
  components: {Footer},
  data() {
    return {
      isLastName:false,
      isSurname:false,
      isSchoolCompany:false,
      isPhone:false,
      isEmail:false,
      isTextarea:false,
      RewardsData:{
        last_name: '',
        surname: '',
        schoolCompany:'',
        phone: '',
        email: '',
        textarea:'',
        type: false
      }
    }
  },
  methods: {
    getLastName(event) {
      if(this.RewardsData.last_name === '') {
        this.isLastName = true;
        this.RewardsData.type = false;
      }else {
        this.isLastName = false;
        this.RewardsData.last_name = event;
        this.RewardsData.type = true;
      }
    },
    getSurname(event) {
      if(this.RewardsData.surname === '') {
        this.isSurname = true;
        this.RewardsData.type = false;
      }else {
        this.isSurname = false;
        this.RewardsData.surname = event;
        this.RewardsData.type = true;
      }
    },
    getSchoolCompany(event) {
      if(this.RewardsData.schoolCompany === '') {
        this.isSchoolCompany = true;
        this.RewardsData.type = false;
      }else {
        this.isSchoolCompany = false;
        this.RewardsData.schoolCompany = event;
        this.RewardsData.type = true;
      }
    },
    getPhone(event) {
      let regContact = new RegExp("^[0-9]*$");
      if (event === "" || (event !== "" && !regContact.test(event))) {
        this.isPhone = true;
        this.RewardsData.type = false;
      }
      else {
        this.isPhone = false;
        this.RewardsData.phone = event;
        this.RewardsData.type = true;

      }
    },
    getEmail(event) {
      let regEmail = /.+@.+\.[a-zA-Z]{2,4}$/;
      if(event === "" || (event !== "" && !regEmail.test(event))){
        this.isEmail = true;
        this.RewardsData.type = false;
      }
      else{
        this.isEmail = false;
        this.RewardsData.email = event;
        this.RewardsData.type = true;
      }
    },
    getTextarea(event) {
      if(this.RewardsData.textarea === '') {
        this.isTextarea = true;
        this.RewardsData.type = false;
      }else {
        this.isTextarea = false;
        this.RewardsData.textarea = event;
        this.RewardsData.type = true;
      }
    },
    getSubmit() {
      let regContact = new RegExp("^[0-9]*$");
      let regEmail = /.+@.+\.[a-zA-Z]{2,4}$/;
      if(this.RewardsData.last_name === ''){this.isLastName = true; this.RewardsData.type = false;}
      if(this.RewardsData.surname === ''){this.isSurname = true; this.RewardsData.type = false;}
      if(this.RewardsData.schoolCompany === ''){this.isSchoolCompany = true; this.RewardsData.type = false;}
      if(this.RewardsData.phone === '' || !regContact.test(this.RewardsData.phone)){this.isPhone = true; this.RewardsData.type = false;}
      if(this.RewardsData.email === '' || !regEmail.test(this.RewardsData.email)){this.isEmail = true; this.RewardsData.type = false;}
      if(this.RewardsData.textarea === ''){this.isTextarea = true; this.RewardsData.type = false;}

      if(this.RewardsData.type){


        // 发送POST请求到后端 sendEmail.jsp
        axios.post('https://schoolnet.monkeytree.com.hk/monkeyTree/recruitment_send_email.jsp', {
          last_name: this.RewardsData.last_name,
          surname: this.RewardsData.surname,
          schoolCompany: this.RewardsData.schoolCompany,
          phone: this.RewardsData.phone,
          email: this.RewardsData.email,
          textarea: this.RewardsData.textarea
        }, {
   headers: {
      'Content-Type': 'application/x-www-form-urlencoded' // 设置请求头
    }
})
        .then(res => {
          console.log(res);
		          console.log("成功提交！",this.RewardsData)
				  console.log("成功提交！",res.data)
          if (res.data.status === 200) {
            // 处理成功返回的数据
            console.log("邮件发送成功", res.data.result);
            this.$confirm('Submission Successful !', '', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              // type: 'warning',// 图标
              center: true
            })
          } else {
            // 处理失败的情况
            console.error("邮件发送失败", res.data.message);
          }
        })
        .catch(error => {
          // 其他错误
                console.log('Error', error.message);
        });
        // this.$api.getContact({
        //   last_name:this.RewardsData.last_name,
        //   surname:this.RewardsData.surname,
        //   schoolCompany:this.RewardsData.schoolCompany,
        //   phone:this.RewardsData.phone,
        //   email:this.RewardsData.email,
        //   textarea:this.RewardsData.textarea,
        // })
        // .then(res=>{
        //   console.log(res)
        //   if(res.data.status===200){
        //     return resolve(res.data.result);
        //   }else{
        //     return resolve([])
        //   }
        // })
        // this.$confirm('Submission Successful !', '', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   // type: 'warning',// 图标
        //   center: true
        // })
      }
    },
  }
}
</script>

<style>
.el-popup-parent--hidden {
  overflow: hidden;
  padding-right: 0px !important;
}
.el-message-box--center .el-message-box__btns {
  display: none;
}
.el-message-box--center  {
  padding-bottom: 50px !important;
  background:#DA191E !important;
  color: #fff;
  border: 0 !important;
}
.el-message-box__message p {
  line-height: 24px;
  color: #fff;
  font-size: 26px;
  margin: 10% 0% !important;
}
.el-message-box__headerbtn .el-message-box__close {
  color: #fff !important;
  font-size: 28px;
}
.el-message-box__headerbtn .el-message-box__close:hover {
  color: #fff;
  opacity: 0.8;
}
</style>